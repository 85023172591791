<template>
<div class="row tgju-widgets-row">
    <div class="col-12 text-center not-found-wrapper">
        <div class="not-found-cover">
            <img src="https://static.tgju.org/views/default/images/shop/404-e.svg?01" alt="صفحه مورد نظر موجود نمی&zwnj;باشد" style="width: 35%;margin-bottom: -50px;margin-top: -50px;">
        </div>
        <div class="clear"></div>
        <h2>صفحه مورد نظر موجود نمی&zwnj;باشد</h2>
    </div>
</div>
</template>

<script>
// این ویو برای صفحه 404 استفاده میشود
export default {
    name: 'notFound',
    components: {},
    data: function () {
        return {}
    },
    mounted() {},
    methods: {},
}
</script>
